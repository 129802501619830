import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import { useParams } from 'react-router-dom';

function CamUp() {
    const [token, setToken] = useState(null);
    const [room, setRoom] = useState(null);
    const { roomName: roomNameFromURL } = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');  // New state for phone number

    useEffect(() => {
      // If there's a room name in the URL, try to join that room immediately
      if (roomNameFromURL) {
          fetchTokenAndJoinRoom(roomNameFromURL);
      }
      console.log('token', token)
    }, [roomNameFromURL]);

    const fetchTokenAndJoinRoom = (roomName) => {
      fetch(`https://2e2a-136-28-30-157.ngrok-free.app/token?identity=colbyallen&roomName=${roomName}`)
      .then(res => {
          if (!res.ok) {
              throw new Error(`Network response was not ok: ${res.statusText}`);
          }
          return res.json();
      })
      .then(data => {
          console.log("Parsed data:", data);
          if (!data.token) {
              throw new Error('Token not present in response');
          }
          setToken(data.token);
          joinRoom(roomName);
      })
      .catch(error => {
          console.error('Error fetching token or joining room:', error);
      });  
    };


    const startCall = () => {
      fetch(`https://2e2a-136-28-30-157.ngrok-free.app/initiateCall?phoneNumber=${phoneNumber}`)  // Pass the phone number
          .then(res => res.json())
          .then(data => {
              fetchTokenAndJoinRoom(data.roomName);
          })
          .catch(error => {
              console.error('Error during call initiation:', error);
          });
  };

    const joinRoom = (roomName) => {
      if (token) {
        Video.connect(token, { name: roomName })
          .then(room => {
              console.log(`Connected to Room ${room.name}`);
              setRoom(room);
          })
          .catch(error => {
              console.error(`Unable to connect to Room: ${error.message}`);
          });
      }
    };

    return (
      <div className="CamUp">
      {!room ? (
          <>
              <input 
                  type="text" 
                  placeholder="Enter phone number" 
                  value={phoneNumber} 
                  onChange={e => setPhoneNumber(e.target.value)} 
              />
              <button onClick={startCall}>Start a Call</button>
          </>
      ) : (
          <>
              <p>You are in the room!</p>
              <button onClick={() => room && room.disconnect()}>Leave Room</button>
          </>
      )}
  </div>
    );
}

export default CamUp;
