import React, { useState, useEffect } from 'react';
import VoterProfile from './VoterProfileCard';
import LikedMusic from './LikedMusicCard';
import MusicProfile from './MusicProfileCard';
import PlayerCard from './PlayerCard';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useLocation } from 'react-router-dom';
import UploadForm from './UploadForm';

function UserProfile({ user: existingUser, votesToGoLivePercentage, sessionProgressPercentage, playlistProgress, streak }) {
    const location = useLocation();
    const [fetchedUser, setFetchedUser] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null)
    const [linkType, setLinkType] = useState(null)
    // const SERVER_URL = 'http://localhost:3000';
    const SERVER_URL = 'https://www.camup.live';


    const getUser = async () => {
      const docRef = doc(db, 'users', existingUser.uid);
      const docSnap = await getDoc(docRef);
      setLoggedInUser(docSnap.data())
    }

    useEffect(() => {
        if (!existingUser) {
            const params = new URLSearchParams(location.search);
            const guid = params.get('guid');

            if (guid) {
              fetch(`${SERVER_URL}/validateGuid?guid=${guid}`)
              .then(response => response.json())
              .then(data => {
                if (data.user) {
                  setFetchedUser(data.user);
                  setLinkType(data.linkType);
                }

              });
            }
        } else {
          getUser()
        }
    }, [location, SERVER_URL, existingUser]);

    const userToUse = loggedInUser || fetchedUser;

    return (
        <>
          <UploadForm type={linkType} user={userToUse} />
        </>
    );
};

export default UserProfile;
