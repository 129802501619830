import "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA-ZwknXLd9AueDELRFIUau-jKFK9exH2A",
  authDomain: "timboido.firebaseapp.com",
  projectId: "timboido",
  storageBucket: "timboido.appspot.com",
  messagingSenderId: "131328639880",
  appId: "1:131328639880:web:7f89c6b91700a14fd5703d",
  measurementId: "G-C0DJM575RT"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

export { db, auth, provider };
